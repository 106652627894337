import FormDynamicMainInput from '@/components/formDynamic/components/nonPrimitiveType/formDynamicMainInput';
import NoData from '@/components/helpers/noData';
import useTrans from "@/hooks/useTrans";
import {
  postDynamicFormRefactor,
  uploadAnyFile,
} from '@/services/formDynamicApis';
import variables from '@/styles/variables.module.scss';
import {
  Button,
  Center,
  Grid,
  Group,
  Loader,
  Stack,
  Text,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { openContextModal } from '@mantine/modals';
import convert from 'color-convert';
import CryptoJS from "crypto-js";
import { useSearchParams } from 'next/navigation';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import {
  LocalStorageKey,
  newTenants,
  showFailureNotification,
} from '../../services/constants';
import {
  mapFormTemplateFieldRefactor,
  mapSectionToFormTemplateFields,
  setupDynamicForm,
} from './services/services';

export default function FormDynamic2(props) {
  const trans = useTrans();
  const [formTemplate, setFormTemplate] = useState(null);
  const [basicTalentInfo, setBasicTalentInfo] = useState(null);
  const [advancedTalentInfo, setAdvancedTalentInfo] = useState(null);
  const [hasCustomError, setHasCustomError] = useState(false);
  const [validateForm, setValidateForm] = useState({});
  const [formDynamicFieldArray, setFormDynamicFieldArray] = useState([]);
  const [finalFormConfig, setFinalFormConfig] = useState(null);
  const [fileToUploadArray, setFileToUploadArray] = useState([]);
  const [doneUploading, setDoneUploading] = useState(false);
  const [isFormLoading, setIsFormLoading] = useState(true);
  const [disabledInput, setDisabledInput] = useState(false);
  const [disabledButton, setDisabledButton] = useState(false);
  const [disabledSubmit, setDisabledSubmit] = useState(false);
  const [requireUpdateInfo, setRequireUpdateInfo] = useState(true);
  const [currentTenant, setCurrentTenant] = useState('');
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const searchParams = useSearchParams();
  const dataOcr = searchParams.get("dataOcr");
  const router = useRouter();

  const convertColor = convert.hex.rgb(props.primaryColor);
  const backgroundColor = `rgba(${convertColor[0]}, ${convertColor[1]}, ${convertColor[2]},0.1)`;

  const formDynamic = useForm({
    initialValues: '',
    validate: validateForm,
  });

  const ekycData = (dataOcr) => {
    const wordArray = CryptoJS.enc.Base64.parse(dataOcr);

    const decodedString = CryptoJS.enc.Utf8.stringify(wordArray);

    const jsonObject = JSON.parse(decodedString);

    console.log("Chuỗi đã giải mã:", jsonObject);

    return jsonObject;
  }

  const tenant = localStorage.getItem(LocalStorageKey.TENANT);

  useEffect(() => {
    if (props.getDefaultValueApi) {
      props
        .getDefaultValueApi(props.hostNameUrl, router.query.child)
        .then((resTemplate) => {
          if (resTemplate.isError) {
            showFailureNotification(resTemplate.message, resTemplate.errorCode);
            setIsFormLoading(false);
          } else {
            console.log("data getDefaultValueApi", resTemplate.data)
            const defaultValueInfo = resTemplate.data;
            const basicTalentInfoData = defaultValueInfo.application
              ? defaultValueInfo.application
              : defaultValueInfo;

            const advancedTalentInfoData = defaultValueInfo.advancedInfo
              ? defaultValueInfo.advancedInfo
              : null;
            console.log({ basicTalentInfoData, advancedTalentInfoData });
            setBasicTalentInfo(basicTalentInfoData);
            setAdvancedTalentInfo(advancedTalentInfoData);
          }
        });
    }
    return () => {
      setBasicTalentInfo(undefined);
      setAdvancedTalentInfo(null);
    };
  }, []);
  useEffect(() => {
    if((dataOcr && tenant.toLowerCase().includes('shlv'))) {
      setDisabledInput(false);
    }
    else if(tenant.toLowerCase().includes('shlv')) {
      setDisabledInput(true);
    }
  }, [tenant, dataOcr])
  useEffect(() => {
    if (
      (basicTalentInfo && router.query.id === 'collect-form') ||
      props.recruitmentPlanId ||
      router.query.id === 'apply-private'
    ) {
      const applicationCode = router.query.child;
      const recruitmentPlanId = props.recruitmentPlanId
        ? props.recruitmentPlanId
        : basicTalentInfo?.recruitmentPlan;

      if (props.getFormTemplateApi) {
        props
          .getFormTemplateApi(
            props.hostNameUrl,
            recruitmentPlanId,
            applicationCode
          )
          .then((resTemplate) => {
            if (resTemplate.isError) {
              showFailureNotification(
                resTemplate.message,
                resTemplate.errorCode
              );
              setIsFormLoading(false);
            } else {
              console.log('resTemplate', resTemplate.data);
              setFormTemplate(resTemplate.data);
              setIsFormLoading(false);
            }
          });
      }
      // setFormTemplate(formTemplateTest)
      // setIsFormLoading(false);
    }
  }, [basicTalentInfo, router.query.id]);

  useEffect(() => {
    if (formTemplate && (!props.useDefaultValue || basicTalentInfo)) {
      const formConfig = {
        initialValues: {
          basics: {},
          ids: {},
        },
        validate: {},
      };

      const tenant = localStorage.getItem(LocalStorageKey.TENANT);
      let returnSetup = undefined;
      let formTemplateFields = [];
      const root = document.documentElement;
      if (tenant) {
        setCurrentTenant(tenant);
        root.style.setProperty(
          '--dynamicFormLabelSize',
          tenant === 'vietbank' ? '14px' : '11px'
        );
      } else {
        root.style.setProperty('--dynamicFormLabelSize', '11px');
      }
      let queryId = router.query.id;
      if (queryId === 'home') {
        queryId = 'recruitments';
      }
      if (!tenant || !newTenants.includes(tenant)) {
        returnSetup = setupDynamicForm(formTemplate.fields, [], queryId);
        formTemplateFields = formTemplate.fields;
      } else {
        if (queryId === 'collect-form' || queryId === 'recruitments') {
          const mappedFormTemplateFields = mapSectionToFormTemplateFields(
            structuredClone(formTemplate)
          );
          formTemplateFields.push(...(mappedFormTemplateFields || []));

          let sectionStatusArray = [];
          if (basicTalentInfo) {
            basicTalentInfo.extraInfos?.forEach((e) => {
              if (e.sectionCode) {
                sectionStatusArray.push({
                  sectionCode: e.sectionCode,
                  sectionStatus: e.status,
                });
              }
            });
          }

          returnSetup = setupDynamicForm(
            formTemplateFields,
            sectionStatusArray,
            queryId
          );
        } else if (queryId === 'apply-private') {
          const mappedFormTemplateFields = structuredClone(
            formTemplate.fields || []
          ).map(mapFormTemplateFieldRefactor);
          formTemplateFields.push(...(mappedFormTemplateFields || []));
          returnSetup = setupDynamicForm(formTemplateFields, [], queryId);
        }
      }
      if (!returnSetup) {
        showFailureNotification('Lỗi khi setup đơn', 'DF-3');
        setIsFormLoading(false);
        return;
      }

      formConfig.initialValues = returnSetup.initialValueObject;
      formConfig.validate = returnSetup.validateObject;

      if (basicTalentInfo && router.query.id === 'collect-form') {
        formConfig.initialValues['recruitmentPlan'] =
          basicTalentInfo.recruitmentPlan;
      }

      if (props.recruitmentPlanId) {
        formConfig.initialValues['recruitmentPlan'] = props.recruitmentPlanId;
      }

      if (props.useDefaultValue) {
        if (!newTenants.includes(tenant)) {
          const initialValueFromApi = advancedTalentInfo
            ? advancedTalentInfo
            : {};
          if (
            basicTalentInfo &&
            basicTalentInfo.talent &&
            basicTalentInfo.talent.basics
          ) {
            initialValueFromApi.basics = { ...basicTalentInfo.talent.basics };
          }
          Object.keys(formConfig.initialValues).forEach((key) => {
            if (initialValueFromApi[key] !== undefined)
              formConfig.initialValues[key] = initialValueFromApi[key];
          });
        } else {
          const isFc = formTemplate.some((e) => e.sectionCode.includes('fc'));
          if (basicTalentInfo.talent?.basics) {
            const key = isFc ? 'basics-fc' : 'basics';
            if (!formConfig.initialValues[key]) {
              formConfig.initialValues[key] = {};
            }
            formConfig.initialValues[key] = Object.assign(
              formConfig.initialValues[key],
              basicTalentInfo.talent?.basics
            );
            formConfig.initialValues[key]["placeOfBirth"] =
              basicTalentInfo.talent?.meta?.placeOfBirth || formConfig.initialValues[key]["placeOfBirth"];

            if (dataOcr && tenant.toLowerCase().includes("shlv")) {
              const ekycDataExp = ekycData(dataOcr);
              formConfig.initialValues[key]["name"] = ekycDataExp.fullName;
              formConfig.initialValues[key]["dob"] = parseDateString(ekycDataExp.dateOfBirth);
              formConfig.initialValues[key]["gender"] = ekycDataExp.gender;
            }
            if (tenant.toLowerCase().includes("svfc")) {
              formConfig.initialValues[key]["identity"] = {
                info: basicTalentInfo.talent?.meta?.identity?.id,
                issuedBy: basicTalentInfo.talent?.meta?.identity?.issuedBy,
                date: basicTalentInfo.talent?.meta?.identity?.date,
              }

              const oldCccd = basicTalentInfo.talent?.ids?.find(
                (s) => s.type === 'oldCccd'
              );

              const oldIssueBy = basicTalentInfo.talent?.ids?.find(
                (s) => s.type === 'oldIssueBy'
              );

              const oldIssueDate = basicTalentInfo.talent?.ids?.find(
                (s) => s.type === 'oldIssueDate'
              );

              formConfig.initialValues[key]["oldIdentity"] = {
                info: oldCccd.id,
                issuedBy: oldIssueBy.id,
                date: oldIssueDate.id,
              }
            }
          }
          if (basicTalentInfo.talent?.meta?.identity) {
            const identity = basicTalentInfo.talent?.meta?.identity;
            const key = isFc ? 'identity-fc' : 'identity';
            if (!formConfig.initialValues[key]) {
              formConfig.initialValues[key] = { identity: {} };
            }

            if (!formConfig.initialValues[key]['identity']) {
              formConfig.initialValues[key]['identity'] = {};
            }
            

          }

          //cmt
          if (dataOcr && tenant.toLowerCase().includes("shlv")) {
            const key = isFc ? "identity-fc" : "identity";

            const jsonObject = ekycData(dataOcr);

            formConfig.initialValues[key]["identity"] = Object.assign(formConfig.initialValues[key]["identity"], {
              info: jsonObject.idNumber,
              date: parseDateString(jsonObject.issuedDate),
              frontFile: jsonObject.pathImgFront,
              backFile: jsonObject.pathImgBack,
              issuedBy: jsonObject.issuedPlace
            });
          }

           if (dataOcr && tenant.toLowerCase().includes("shlv")) {
            const key = isFc ? "location-fc" : "location";

            const jsonObject = ekycData(dataOcr);

            formConfig.initialValues[key]["residenceAddress"] = Object.assign(formConfig.initialValues[key]["residenceAddress"], {
              province: jsonObject.addressDetails.province,
              district: jsonObject.addressDetails.district,
              ward: jsonObject.addressDetails.ward,
              street: jsonObject.addressDetails.street,
            });
          }

          basicTalentInfo.extraInfos?.forEach((extraInfo) => {
            if (extraInfo.sectionCode) {
              formConfig.initialValues[extraInfo.sectionCode] = extraInfo.data;

              const index = formTemplateFields.findIndex(
                (e) => e.name === extraInfo.sectionCode
              );
              if (index >= 0) {
                if (extraInfo.note) {
                  formTemplateFields[index].sectionNote = extraInfo.note;
                }
                if (extraInfo.status) {
                  formTemplateFields[index].sectionStatus = extraInfo.status;
                }
              }
            }
          });
        }
        // setDisabledInput(
        //   basicTalentInfo &&
        //   basicTalentInfo.requireUpdateInfo !== undefined &&
        //   !basicTalentInfo.requireUpdateInfo
        // );
        setRequireUpdateInfo(
          basicTalentInfo &&
          basicTalentInfo.requireUpdateInfo !== undefined &&
          basicTalentInfo.requireUpdateInfo
        );
      }

      formDynamic.setValues(formConfig.initialValues);
      setValidateForm(formConfig.validate);
      setFormDynamicFieldArray(formTemplateFields);
      setFinalFormConfig(formConfig);
      setIsFormLoading(false);
    }
  }, [formTemplate, basicTalentInfo, advancedTalentInfo, dataOcr]);

  useEffect(() => {
    if (doneUploading) {
      const handleFormSuccess = (
        talentApplyResultCode = null,
        successMessage = ''
      ) => {
        let handleClose = () => {};

        if (["collect-form", "apply-private"].includes(router.query.id)) {
          // console.log("router.query.id", router.query.id)
          handleClose = () => {
            setDisabledInput(true);
          };
        } else if (props.closeModal) {
          handleClose = props.closeModal;
        }

        openContextModal({
          modal: 'success',
          centered: true,
          innerProps: {
            text: trans.applyNow.success,
            content: successMessage,
            color: variables.primaryColor,
            code: talentApplyResultCode,
          },
          onClose: handleClose,
        });
      };

      let formType = 'apply';
      switch (router.query.id) {
        case 'collect-form':
          formType = 'extra-info';
          break;

        case 'apply-private':
          formType = 'private';
          break;
      }

      const originalValues = structuredClone(formDynamic.values);

      const sendData = async () =>
        await postDynamicFormRefactor(
          formDynamic.values,
          props.hostNameUrl,
          formType,
          basicTalentInfo?._id || basicTalentInfo?.applicationId,
          basicTalentInfo.talent.meta,
          formDynamicFieldArray
        );
      sendData().then((responsePost) => {
        if (responsePost.isError) {
          formDynamic.setValues(originalValues);
          showFailureNotification(responsePost.message, responsePost.errorCode);
        } else {
          handleFormSuccess(
            props.useDefaultValue ? null : responsePost.data,
            responsePost.message
          );
          setIsFormSubmitted(true);
        }

        setFileToUploadArray([]);
        setDoneUploading(false);
        setIsFormLoading(false);
      });
    }
  }, [doneUploading]);

  const handleClick = (e) => {
    e.preventDefault();
    console.log('values', formDynamic.values);
    console.log('errors', formDynamic.errors);

    if (!formDynamic.validate().hasErrors && !hasCustomError) {
      setIsFormLoading(true);
      const fileUploadPromises = fileToUploadArray.map((fileToUpload) => {
        const inputTemplate = fileToUpload.inputTemplate;
        const uploadFile = async (fileSingle) =>
          await uploadAnyFile(fileSingle, props.hostNameUrl);
        const returnObject = {
          value: null,
          key: fileToUpload.inputName,
        };

        if (Array.isArray(fileToUpload.file)) {
          const fileArray = fileToUpload.file;
          const fileArrayPromise = fileArray.map((fileSingle) => {
            return uploadFile(fileSingle).then((res) => {
              if (res && res.url) {
                if (
                  inputTemplate &&
                  inputTemplate.metadata?.urlOnly === false
                ) {
                  return res;
                }
                return res.url;
              }
              return null;
            });
          });
          return Promise.all(fileArrayPromise).then((resData) => {
            returnObject.value = resData;
            return returnObject;
          });
        } else {
          const fileSingle = fileToUpload.file;
          return uploadFile(fileSingle).then((res) => {
            if (res && res.url) {
              if (inputTemplate && inputTemplate.metadata?.urlOnly === false) {
                returnObject.value = res;
              } else {
                returnObject.value = res.url;
              }
            }
            return returnObject;
          });
        }
      });

      if (fileUploadPromises.length === 0) {
        setDoneUploading(true);
      } else {
        Promise.all(fileUploadPromises).then((fileUploadResArray) => {
          fileUploadResArray.forEach((fileUploadRes, index) => {
            if (fileUploadRes)
              formDynamic.setFieldValue(fileUploadRes.key, fileUploadRes.value);

            if (index === fileUploadResArray.length - 1) {
              setDoneUploading(true);
            }
          });
          setFileToUploadArray([]);
        });
      }
    } else {
      showFailureNotification(
        'Thông tin điền vào đơn chưa hợp lệ. Vui lòng kiểm tra lại',
        'DF-2'
      );
    }
  };

  const currentWidth = props.windowDimension.width;
  let inputRowSpacing = 24;
  const fontDivider = currentWidth >= 800 ? 1 : 1.5;
  let numInputPerRow = 3;
  let padding = 44;

  if (currentWidth <= 1000) {
    numInputPerRow = 2;
    inputRowSpacing = 22;
    padding = 22;
  }
  if (currentWidth <= 800) {
    numInputPerRow = 1;
    inputRowSpacing = 20;
    padding = 15;
  }

  // const disabledInput = basicTalentInfo && basicTalentInfo.requireUpdateInfo !== undefined && !(basicTalentInfo.requireUpdateInfo);

  const formDynamicFieldPriorityFields = formDynamicFieldArray.filter(
    (e) => e.priority !== undefined
  );
  const formDynamicFieldNonPriorityFields = formDynamicFieldArray.filter(
    (e) => e.priority === undefined
  );
  const formDynamicFieldArraySorted = [
    ...formDynamicFieldPriorityFields,
    ...formDynamicFieldNonPriorityFields,
  ];

  const inputSectionJSXs = formDynamicFieldArraySorted.map(
    (fieldTemplateMain, index) => {
      return (
        <FormDynamicMainInput
          key={index}
          hostNameUrl={props.hostNameUrl}
          formDynamic={formDynamic}
          finalFormConfig={finalFormConfig}
          numInputPerRow={numInputPerRow}
          fieldTemplateMain={fieldTemplateMain}
          currentWidth={currentWidth}
          backgroundColor={backgroundColor}
          primaryColor={props.primaryColor}
          inputRowSpacing={inputRowSpacing}
          disabledInput={disabledInput}
          requireUpdateInfo={requireUpdateInfo}
          setDisabledInput={setDisabledInput}
          disabledButton={disabledButton}
          setDisabledButton={setDisabledButton}
          setHasCustomError={setHasCustomError}
          currentNameArray={
            fieldTemplateMain.name ? [fieldTemplateMain.name] : []
          }
          setFileToUploadArray={setFileToUploadArray}
          fileToUploadArray={fileToUploadArray}
          setDisabledSubmit={setDisabledSubmit}
          sectionStatus={fieldTemplateMain.sectionStatus}
          sectionNote={fieldTemplateMain.sectionNote}
          isFormSubmitted={isFormSubmitted}
        />
      );
    }
  );

  let mainContent = (
    <Stack spacing={padding} p={padding}>
      {props.useDefaultValue ? (
        <Stack
          spacing={0}
          style={{
            backgroundColor: backgroundColor,
            margin: -padding,
            padding: 10,
          }}
        >
          <Text
            ta={'center'}
            fw={600}
            fz={32 / fontDivider}
            c={variables.primaryColor}
          >
            {formTemplate
              ? props.collectFormHeader?.title || 'Đơn bổ sung thông tin'
              : 'Đang tải...'}
          </Text>
          <Text ta={'center'} fz={18 / fontDivider} c={'#232323'}>
            {props.collectFormHeader?.description ??
              'Cảm ơn bạn đã ứng tuyển tại công ty. Bạn vui lòng hoàn thành đơn bổ sung thông tin dưới đây trước thời hạn quy định'}
          </Text>
        </Stack>
      ) : (
        <></>
      )}
      {!requireUpdateInfo && newTenants.includes(currentTenant) && (
        <Text
          ta={'center'}
          fw={600}
          fz={32 / fontDivider}
          mb={-padding}
          c={'red'}
        >
          Thông tin đang được kiểm tra.
        </Text>
      )}

      <Grid columns={12} m={0} gutter={inputRowSpacing}>
        {inputSectionJSXs}
      </Grid>
      {isFormLoading && (
        <Center>
          <Stack
            justify={'center'}
            align={'center'}
            style={{ height: '100%' }}
            spacing={20}
          >
            <Loader color={props.primaryColor} />
            <Text fw={700} c={props.primaryColor}>
              Đang tải...
            </Text>
          </Stack>
        </Center>
      )}

      {formTemplate && !disabledInput && requireUpdateInfo && !isFormLoading && (!tenant.toLowerCase().includes("shlv")) ? (
        <Group position={"center"}>
          <Button
            type={'submit'}
            bg={variables.primaryColor}
            disabled={disabledSubmit}
            style={{ width: 200, borderRadius: 100 }}
            onClick={(e) => handleClick(e)}
          >
            {trans.apply}
          </Button>
        </Group>
      ) : (
        <></>
      )}

      {formTemplate && !disabledInput && requireUpdateInfo && !isFormLoading && (tenant.toLowerCase().includes("shlv")) && dataOcr ? (
        <Group position={"center"}>
          <Button
            type={'submit'}
            bg={variables.primaryColor}
            disabled={disabledSubmit}
            style={{ width: 200, borderRadius: 100 }}
            onClick={(e) => handleClick(e)}
          >
            {trans.apply}
          </Button>
        </Group>
      ) : (
        <></>
      )}
    </Stack>
  );

    function parseDateString(dateString) {
      const parts = dateString.split('/');
      const day = parseInt(parts[0], 10);
      const month = parseInt(parts[1], 10) - 1;
      const year = parseInt(parts[2], 10);

      return new Date(year, month, day);
  }

  const badContent = (
    <Center>
      <Stack
        justify={'center'}
        align={'center'}
        style={{ height: '100vh' }}
        spacing={20}
      >
        <NoData
          hasImage={true}
          hasText={true}
          noDataText={`Không Có Ứng Viên Với Mã ${props.talentCode}`}
        />
      </Stack>
    </Center>
  );

  // const loadingContent = (
  //     <Center>
  //         <Stack justify={"center"} align={"center"} style={{height: "100vh"}} spacing={20}>
  //             <Loader color={props.primaryColor}/>
  //             <Text fw={700} c={props.primaryColor}>Đang tải...</Text>
  //         </Stack>
  //     </Center>
  // )

  return basicTalentInfo || !props.useDefaultValue ? mainContent : badContent;
}

import {
  applicationCollectFormTenants,
  FormSectionStatus,
  getResponseFormDynamicApi,
  LocalStorageKey,
  newTenants,
  ResponseFormDynamicApi,
} from "@/services/constants";
import { getLayout } from "@/services/services";
import {
  QueryFilter,
  QueryFilterArr,
  RequestQueryBuilder,
} from "@nestjsx/crud-request";
import axios, { AxiosResponse } from "axios";
import * as https from "https";

interface FileUploadResponse {
  name: string;
  type: string;
  url: string;
}

export async function getFormApplyPrivateApi(
  hostNameUrl?: string,
  recruitmentPlanId?: string,
  applicationCode?: string
) {
  let actualHostName =
    process.env.NEXT_PUBLIC_API_URL || `https://${hostNameUrl}/api`;

  const url = `${actualHostName}/portals/form-templates/talent-apply`;

  const response = await axios
    .get(url, {
      headers: {
        "ngrok-skip-browser-warning": "1",
        "Access-Control-Allow-Origin": "*",
      },
    })
    .catch((error) => null);

  return response
    ? getResponseFormDynamicApi(false, null, response.data, "")
    : getResponseFormDynamicApi(
        true,
        "DF-AP-1-0",
        null,
        "Đã xảy ra lỗi ở server khi lấy template cho form"
      );
}

export async function getFormAdvancedInfoTemplateApi(
  hostNameUrl: string,
  recruitmentPlanId?: string,
  applicationCode?: string
): Promise<ResponseFormDynamicApi> {
  let actualHostName =
    process.env.NEXT_PUBLIC_API_URL || `https://${hostNameUrl}/api`;
  let res = undefined;
  let tenant: string | null = localStorage.getItem(LocalStorageKey.TENANT);
  if (!tenant) {
    res = await getLayout(hostNameUrl, false);
    if (!res) {
      return getResponseFormDynamicApi(
        true,
        "DF-AIF-1-1",
        null,
        "Đã xảy ra lỗi ở server khi lấy template cho form"
      );
    }
    tenant = res.tenant;
  }

  const qb = RequestQueryBuilder.create();
  if (tenant.length > 0) {
    const filterArray: (QueryFilter | QueryFilterArr)[] = [
      {
        field: "tenant",
        operator: "$eq",
        value: tenant,
      },
    ];
    qb.setFilter(filterArray);
  }

  let url = `${actualHostName}/portals/masterdata/form/talent-info?${qb.query()}`;
  if (newTenants.includes(tenant)) {
    url = `${actualHostName}/portals/recruitments/${recruitmentPlanId}/form-template/PortalTalentExtraInfo`;
  }

  if (applicationCode && applicationCollectFormTenants.includes(tenant)) {
    url = `${actualHostName}/portals/applications/${applicationCode}/form-template/PortalTalentExtraInfo`;
  }

  const response = await axios
    .get(url, {
      headers: {
        "ngrok-skip-browser-warning": "1",
        "Access-Control-Allow-Origin": "*",
      },
    })
    .catch((error) => null);
  return response
    ? getResponseFormDynamicApi(false, null, response.data, "")
    : getResponseFormDynamicApi(
        true,
        "DF-AIF-1-0",
        null,
        "Đã xảy ra lỗi ở server khi lấy template cho form"
      );
}

export async function getTalentInfoApi(
  hostNameUrl: string | undefined,
  code: string
): Promise<ResponseFormDynamicApi> {
  let actualHostName =
    process.env.NEXT_PUBLIC_API_URL || `https://${hostNameUrl}/api`;

  let tenant: string | null = localStorage.getItem(LocalStorageKey.TENANT);
  if (!tenant) {
    const res = await getLayout(hostNameUrl, false);
    if (!res) {
      return getResponseFormDynamicApi(
        true,
        "DF-AIF-1-1",
        null,
        "Đã xảy ra lỗi ở server khi lấy template cho form"
      );
    }
    tenant = res.tenant;
  }

  let url = `${actualHostName}/portals/applications/${code}/advanced-info`;
  if (newTenants.includes(tenant)) {
    url = `${actualHostName}/portals/applications/${code}/extra-info`;
  }

  const response: AxiosResponse<{
    application: any;
    advancedInfo: any;
  }> | null = await axios
    .get(url, {
      headers: {
        "ngrok-skip-browser-warning": "1",
        "Access-Control-Allow-Origin": "*",
      },
    })
    .catch((error) => null);
  return response
    ? getResponseFormDynamicApi(false, null, response.data, "")
    : getResponseFormDynamicApi(
        true,
        "DF-AIF-2",
        null,
        "Đã xảy ra lỗi ở server khi lấy thông tin ứng viên"
      );
}

export async function postFormTalentAdvancedInfo(
  formDynamicData: any,
  hostNameUrl: string,
  talentApplicationId: string
) {
  let actualHostName =
    process.env.NEXT_PUBLIC_API_URL || `https://${hostNameUrl}/api`;

  let errorCode = null;
  const fileUploadPromise: Promise<FileUploadResponse | null>[] = [];
  const copyFormDynamicData = { ...formDynamicData };

  const certificateKey = formDynamicData.certificate
    ? "certificate"
    : "certificates";
  formDynamicData[certificateKey].forEach(
    (certificateSingle: any, index: number) => {
      if (certificateSingle.file instanceof File) {
        fileUploadPromise.push(
          uploadAnyFile(certificateSingle.file, hostNameUrl).then((fileRes) => {
            copyFormDynamicData[certificateKey][index].file = fileRes
              ? fileRes.url
              : "Lỗi xảy ra khi tải hình ảnh/tải danh sách";
            return fileRes;
          })
        );
      }
    }
  );

  if (formDynamicData["identityFiles"]) {
    formDynamicData["identityFiles"].forEach(
      (identityFile: any, index: number) => {
        if (identityFile instanceof File)
          fileUploadPromise.push(
            uploadAnyFile(identityFile, hostNameUrl).then((fileRes) => {
              copyFormDynamicData["identityFiles"][index] = fileRes
                ? fileRes.url
                : "Lỗi xảy ra khi tải hình ảnh/tải danh sách";
              return fileRes;
            })
          );
      }
    );
  }

  const url = `${actualHostName}/portals/applications/${talentApplicationId}/talent-advanced-info`;
  const axiosResponseData = await Promise.all(fileUploadPromise)
    .then((res) => {
      return axios
        .post(url, copyFormDynamicData, {
          headers: {
            "Access-Control-Allow-Origin": "*",
          },
        })
        .then((response) => {
          if (response.data && response.data._id) return response.data;
          else {
            errorCode = "DF-AIF-3-1";
            return null;
          }
        })
        .catch((error) => {
          errorCode = "DF-AIF-3-2";
          return null;
        });
    })
    .catch((error) => {
      errorCode = "DF-AF-3-3";
      return null;
    });

  return axiosResponseData
    ? getResponseFormDynamicApi(
        false,
        null,
        axiosResponseData,
        "Cảm ơn bạn đã nộp đơn bổ sung thông tin"
      )
    : getResponseFormDynamicApi(
        true,
        errorCode,
        null,
        "Đã xảy ra lỗi ở server"
      );
}

export async function getLocationData(
  masterDataToUpdateString: string,
  locationCode: number,
  hostNameUrl: string
): Promise<ResponseFormDynamicApi> {
  let actualHostName =
    process.env.NEXT_PUBLIC_API_URL || `https://${hostNameUrl}/api`;

  let resLayout = undefined;
  let tenant: string | null = localStorage.getItem(LocalStorageKey.TENANT);
  if (!tenant) {
    resLayout = await getLayout(hostNameUrl, false);
    if (!resLayout) {
      return getResponseFormDynamicApi(
        true,
        "DF-AIF-4-2",
        null,
        "Đã xảy ra lỗi ở server khi lấy thông tin của vị trí"
      );
    }
    tenant = resLayout.tenant;
  }

  const qb = RequestQueryBuilder.create();

  if (tenant.length > 0) {
    qb.setFilter({
      field: "tenant",
      operator: "$eq",
      value: tenant,
    });
  }

  const url = `${actualHostName}/portals/masterdata/${masterDataToUpdateString}/data?ref=${locationCode}&${qb.query()}`;

  const response = await axios
    .get(url, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "multipart/form-data",
      },
    })
    .catch((error) => null);
  return response
    ? getResponseFormDynamicApi(false, null, response.data, "")
    : getResponseFormDynamicApi(
        true,
        "DF-AIF-4-1",
        null,
        "Đã xảy ra lỗi ở server khi lấy thông tin của vị trí"
      );
}

export async function getFormQuickApplyTemplateApi(
  hostNameUrl?: string,
  recruitmentPlanId?: string,
  applicationCode?: string
) {
  let actualHostName =
    process.env.NEXT_PUBLIC_API_URL || `https://${hostNameUrl}/api`;

  const formName = process.env.NEXT_PUBLIC_API_URL
    ? actualHostName.slice(8, -4)
    : hostNameUrl;

  let resLayout = undefined;
  let tenant: string | null = localStorage.getItem(LocalStorageKey.TENANT);
  if (!tenant) {
    resLayout = await getLayout(hostNameUrl, false);
    if (!resLayout) {
      return getResponseFormDynamicApi(
        true,
        "DF-QFA-5-2",
        null,
        "Đã xảy ra lỗi khi lấy template form apply nhanh"
      );
    }
    tenant = resLayout.tenant;
  }
  const qb = RequestQueryBuilder.create();
  if (tenant.length > 0) {
    const filterArray: (QueryFilter | QueryFilterArr)[] = [
      {
        field: "tenant",
        operator: "$eq",
        value: tenant,
      },
    ];
    qb.setFilter(filterArray);
  }

  let url = `${actualHostName}/portals/masterdata/form/form-${formName}?${qb.query()}`;

  if (newTenants.includes(tenant)) {
    url = `${actualHostName}/portals/recruitments/${recruitmentPlanId}/form-template/PortalTalentApplyRecruitment`;
  }

  console.log("url", url);

  const response = await axios
    .get(url, {
      headers: {
        "ngrok-skip-browser-warning": "1",
        "Access-Control-Allow-Origin": "*",
      },
    })
    .catch((error) => null);

  console.log("response", response);
  return response
    ? getResponseFormDynamicApi(false, null, response.data, "")
    : getResponseFormDynamicApi(
        true,
        "DF-QFA-5-1",
        null,
        "Đã xảy ra lỗi khi lấy template form apply nhanh"
      );
}

export async function postFormQuickApply(
  formDynamicData: any,
  hostNameUrl: string
) {
  const postFormData: any = {
    basics: {
      location: {},
      profiles: [],
      label: "",
    },
    meta: {
      source: "portal",
    },
    tags: ["public"],
  };
  const fileResDataArray: FileUploadResponse[] = [];
  const fileUploadPromises: Promise<FileUploadResponse | null>[] = [];
  const errorServerMessage = "Đã xảy ra lỗi khi nộp đơn ứng tuyển nhanh";

  const updateFormData = (
    formDynamicDataValue: any,
    key: string,
    formDynamicDataObjectKey: string | null
  ) => {
    if (formDynamicDataValue instanceof File) {
      const uploadFileFunc = async () =>
        await uploadAnyFile(formDynamicDataValue, hostNameUrl);
      fileUploadPromises.push(
        uploadFileFunc()
          .then((fileResData) => {
            if (fileResData) {
              fileResDataArray.push({ ...fileResData });
              return fileResData;
            }
            return null;
          })
          .catch((error) => null)
      );
    } else {
      if (formDynamicDataObjectKey)
        postFormData[key][formDynamicDataObjectKey] = formDynamicDataValue;
      else {
        postFormData[key] = formDynamicDataValue;
      }
    }
  };

  Object.keys(formDynamicData).forEach((key) => {
    if (typeof formDynamicData[key] === "object") {
      if (!Object.keys(postFormData).includes(key)) postFormData[key] = {};
      Object.keys(formDynamicData[key]).forEach((formDynamicDataObjectKey) => {
        const formDynamicDataValue =
          formDynamicData[key][formDynamicDataObjectKey];
        updateFormData(formDynamicDataValue, key, formDynamicDataObjectKey);
      });
    } else {
      const formDynamicDataValue = formDynamicData[key];
      if (key === "email") {
        updateFormData(formDynamicDataValue, "basics", key);
      } else {
        updateFormData(formDynamicDataValue, key, null);
      }
    }
  });
  return Promise.all(fileUploadPromises)
    .then((res) => {
      let errorPromiseAll = false;
      res.forEach((eachRes) => {
        if (!eachRes) errorPromiseAll = true;
      });

      if (errorPromiseAll) {
        return getResponseFormDynamicApi(
          true,
          "DF-QAF-1",
          null,
          errorServerMessage
        );
      }

      postFormData["files"] = [...fileResDataArray];

      let actualHostName =
        process.env.NEXT_PUBLIC_API_URL || `https://${hostNameUrl}/api`;
      const url = `${actualHostName}/portals/talents`;
      const postForm = async () => await axios.post(url, postFormData);
      return postForm()
        .then((res) => {
          if (res.data) {
            const formApplyResult = res.data;
            if (formApplyResult.code) {
              return getResponseFormDynamicApi(
                false,
                null,
                formApplyResult.code,
                "Cảm ơn bạn đã nộp đơn ứng tuyển nhanh"
              );
            }
          }
          return getResponseFormDynamicApi(
            true,
            "DF-QAF-2",
            null,
            errorServerMessage
          );
        })
        .catch((error) =>
          getResponseFormDynamicApi(true, "DF-QAF-3", null, errorServerMessage)
        );
    })
    .catch((error) =>
      getResponseFormDynamicApi(true, "DF-QAF-4", null, errorServerMessage)
    );
}

export async function uploadAnyFile(
  file: File,
  hostNameUrl: string
): Promise<FileUploadResponse | null> {
  let actualHostName =
    process.env.NEXT_PUBLIC_API_URL || `https://${hostNameUrl}/api`;
  let bodyFormData = new FormData();
  bodyFormData.append("file", file);

  const useFileUrlConfig = localStorage.getItem(
    LocalStorageKey.USE_FILE_URL_CONFIG
  );
  let fileUrl: string | null = null;
  if (useFileUrlConfig) {
    fileUrl = localStorage.getItem(LocalStorageKey.FILE_URL);
    if (!fileUrl) {
      const resLayout = await getLayout(hostNameUrl, false);
      if (!resLayout) {
        return null;
      }
      fileUrl = resLayout.layout?.config?.fileUrl || null;
    }
  }

  const url = fileUrl || `${actualHostName}/portals/file-manager`;
  const agent = new https.Agent({
    rejectUnauthorized: false,
    requestCert: false,
  });

  const response = await axios
    .post(url, bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
        "Access-Control-Allow-Origin": "*",
      },
      httpsAgent: agent,
    })
    .catch((error) => null);

  if (response) {
    let dataResponse = response.data;
    if (Array.isArray(response.data)) {
      dataResponse = response.data[0];
    }
    if (!dataResponse) {
      return null;
    }
    const fileResponse: {
      name: string;
      extension: string;
      url: string;
      size: number;
    } = dataResponse;
    return {
      name: fileResponse.name,
      type: fileResponse.extension,
      url: fileResponse.url,
    };
  }
  return null;
}

export async function postDynamicFormRefactor(
  formValues: any,
  hostNameUrl: string,
  formType: "private" | "apply" | "extra-info",
  talentApplicationId = "",
  meta: any = {},
  fields: any[] = []
) {
  let actualHostName =
    process.env.NEXT_PUBLIC_API_URL || `https://${hostNameUrl}/api`;

  let url: string;
  let tenant: string | null = localStorage.getItem(LocalStorageKey.TENANT);
  if (!tenant) {
    const resLayout = await getLayout(hostNameUrl, false);
    if (!resLayout) {
      return getResponseFormDynamicApi(
        true,
        "DF-APPLY-2",
        null,
        "Đã xảy ra lỗi khi lấy layout để nộp form"
      );
    }
    tenant = resLayout.tenant;
  }
  let postFormData: any = {};
  let errorMessage = "";
  let successMessage = "";

  switch (formType) {
    case "apply":
      if (!newTenants.includes(tenant)) {
        url = `${actualHostName}/portals/talents`;
        const baseFormData: any = {
          meta: {
            source: "portal",
          },
          website: {
            tenant: tenant,
          },
          tags: ["public"],
        };
        postFormData = Object.assign({ ...formValues }, baseFormData);

        const basicFormData = {
          location: {},
          profiles: [],
          label: "",
        };
        postFormData.basics = Object.assign(
          { ...postFormData.basics },
          basicFormData
        );
      } else {
        url = `${actualHostName}/portals/talents/create`;
        postFormData = {
          body: {
            recruitmentPlan: formValues.recruitmentPlan,

            data: [],
          },
          website: {
            tenant: tenant,
          },
        };
        Object.keys(formValues).forEach((formValueKey) => {
          if (
            formValueKey !== "recruitmentPlan" &&
            Object.keys(formValues[formValueKey]).length > 0
          ) {
            postFormData.body.data.push({
              sectionCode: formValueKey,
              data: formValues[formValueKey],
            });
          }
        });

        successMessage = "Cảm ơn bạn đã nộp đơn ứng tuyển nhanh";
        errorMessage = "Đã xảy ra lỗi khi nộp đơn ứng tuyển nhanh";
      }
      break;
    case "extra-info":
      if (!newTenants.includes(tenant)) {
        url = `${actualHostName}/portals/applications/${talentApplicationId}/talent-advanced-info`;
        postFormData = { ...formValues };
      } else {
        url = `${actualHostName}/portals/applications/${talentApplicationId}/talent-extra-info`;
        postFormData = [];
        // Object.keys(formValues).forEach(formValueKey => {
        //     if (formValueKey !== "recruitmentPlan" && Object.keys(formValues[formValueKey]).length > 0) {
        //         postFormData.push({
        //             sectionCode: formValueKey,
        //             data: formValues[formValueKey]
        //         })
        //     }
        // })
        if (fields.length > 0) {
          const filteredField = fields.filter(
            (field) =>
              !field.sectionStatus ||
              field.sectionStatus === FormSectionStatus.PENDING ||
              field.sectionStatus === FormSectionStatus.REJECTED
          );
          filteredField.forEach((field) => {
            if (field.name) {
              if (field.name === "basics") {
                postFormData.push({
                  sectionCode: "basics",

                  data: { ...formValues[field.name], meta: { ...meta } },
                });
              } else {
                postFormData.push({
                  sectionCode: field.name,
                  data: formValues[field.name],
                });
              }
            }
          });
        }
      }
      successMessage = "Cảm ơn bạn đã nộp đơn bổ sung thông tin";
      errorMessage = "Đã xảy ra lỗi khi nộp đơn bổ sung";
      break;

    case "private":
      url = `${actualHostName}/portals/talents/create`;
      postFormData = {
        body: {
          data: [],
        },
        website: {
          tenant: tenant,
        },
      };
      if (fields.length > 0) {
        fields.forEach((field) => {
          if (field.name) {
            postFormData.body.data.push({
              sectionCode: field.name,
              data: formValues[field.name],
            });
          }
        });
      }

      successMessage = "Cảm ơn bạn đã nộp ứng tuyển";
      errorMessage = "Đã xảy ra lỗi khi nộp ứng tuyển";
      break;
  }
  const postForm = async () => await axios.post(url, postFormData);
  return postForm()
    .then((res) => {
      if (res.data) {
        const formResult = res.data;
        let returnData;
        switch (formType) {
          case "apply":
            returnData = formResult.code;
            break;

          case "extra-info":
            returnData = formResult.code;
            break;
        }

        return getResponseFormDynamicApi(
          false,
          null,
          returnData,
          successMessage
        );
      }
      return getResponseFormDynamicApi(true, "DF-APPLY-1", null, errorMessage);
    })
    .catch((error) =>
      getResponseFormDynamicApi(true, "DF-APPLY-2", null, errorMessage)
    );
}
